.dashboard_container {
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
}

.dashboard__body {
  margin: 0 auto;
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-areas:
    'head head'
    'video que'
    'ctrls que';

  grid-template-columns: minmax(auto, 1fr) minmax(auto, 0.1fr);
  grid-template-rows: auto minmax(400px, auto) auto;
  gap: 1em;
  padding: var(--margin-bottom) var(--dashboard-margin);
  overflow: hidden;
  /* justify-content: center; */
}

.dashboard__component {
  position: relative;
  background-color: var(--clr-surface1);
  display: flex;
  height: 100%;
  width: fit-content;
  overflow: hidden;
  box-shadow: var(--shadow-light);
  border-radius: var(--border-radius);
  z-index: 0;
}

#header {
  position: relative;
  height: 100%;
  grid-area: head;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;
  align-items: center;
  column-gap: 1em;
  padding-right: 1em;
}

#info {
  grid-area: info;
  padding: 1em;
  width: 100%;
  height: 100%;
}

#video {
  grid-area: video;
  aspect-ratio: 16/9;
  object-fit: cover;
  height: max-content;
  width: 100%;
}

#queue {
  grid-area: que;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-width: 600px;
}

#player_controls {
  grid-area: ctrls;
  min-height: 120px;
  height: 100%;
  width: 100%;
  display: flex;
}

#search {
  grid-area: srch;
}

#side {
  grid-area: side;
  position: relative;
  display: inline-grid;
  height: 100%;
  width: 300px;
  grid-template-rows: 1fr auto;
  align-items: flex-end;
}

#preview {
  margin-top: 0.5em;
}

@media screen and (max-width: 1500px) {
  .dashboard__body {
    height: auto;
    min-height: max-content;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: 100%;
    grid-template-areas:
      'head'
      'video'
      'que'
      'ctrls';
    overflow-y: auto;
  }

  #queue {
    min-width: 100%;
    height: max-content;
  }
}
@media screen and (max-width: 800px) {
  .dashboard__body {
    padding: var(--dashboard-margin);
    gap: 0;
    row-gap: 1em;
  }

  #preview {
    display: none;
  }
}
