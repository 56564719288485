@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300&display=swap');

:root {
  --breakpoint-l: 1150px;
  --breakpoint-m: 800px;
  --breakpoint-s: 576px;

  --clr-warning: #d7a936;
  --clr-error: #de4456;

  --shadow-light: 0 4px 4px rgba(0, 0, 0, 0.25);
  --shadow-heavy: 0 8px 8px rgba(0, 0, 0, 0.25);

  --margin-bottom: 1.5em;
  --border-radius: 0.5em;
  --btn-border-radius: 0.3em;
  --navbar-width: 250px;
  --thumb-width: 120px;
  --thumb-height: 90px;
  --search-bar-width: 450px;
  --search-bar-height: 3rem;

  --player-width: 350px;
  --header-height: 3em;

  --selection-width: 400px;
  --bar-height: 0.8em;

  --footer-height: 200px;
  --max-video-height: 350px;

  --margin-top: 300px;
  --srch-margin-left: 0px;

  --dashboard-margin: 2em 1em;
  --dashboard-margin-top: 2em;
}

[data-theme='dark'] {
  --clr-bg: #0e0e12;
  --clr-surface0: #131619;
  --clr-surface1: #16161b;
  --clr-surface2: #131516;
  --clr-surface3: #343647;
  --clr-accent: #9c2c30;

  --clr-font: #e8e6e3;
  --clr-title: #cdc8c2;

  --clr-btn: #777777;
  --clr-btn-inactive: #423f40;
  --clr-btn-active: var(--clr-accent);
}

[data-theme='light'] {
  --clr-bg: #f4f4f5;
  --clr-surface0: #f2f3f2;
  --clr-surface1: #f1f1f1;
  --clr-surface2: #f1f1f1;
  --clr-surface3: #cccccc;
  --clr-accent: #de3b40;

  --clr-font: #3b465c;
  --clr-title: #060606;

  --clr-btn: #1f1f1f;
  --clr-btn-inactive: #4e4e4e;
  --clr-btn-active: var(--clr-accent);
}

@media screen and (max-width: 1150px) {
  :root {
    --selection-width: 80%;
    --srch-margin-left: 2em;
    --search-bar-width: calc(450px - var(--srch-margin-left));
  }
}

@media screen and (max-width: 800px) {
  :root {
    font-size: 0.9em;
    --search-bar-width: calc(90% - var(--srch-margin-left));
    --thumb-width: 90px;
    --thumb-height: 60px;
    --margin-bottom: 1em;
  }
}

body {
  height: 100vh;
  width: 100vw;
  background: var(--clr-bg);
  color: var(--clr-font);
  overflow: hidden;
  font-family: 'Roboto Condensed', sans-serif;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* Scrollbar */
.no-overlay-scrollbar ::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar {
  background-color: #202324;
  color: #aba499;
}

::-webkit-scrollbar-corner {
  background-color: #181a1b;
}

::-webkit-scrollbar-thumb {
  background-color: #454a4d;
}

.no-overlay-scrollbar ::-webkit-scrollbar-track-piece {
  background-color: initial;
}
.no-overlay-scrollbar ::-webkit-scrollbar-track-piece {
  background-color: initial;
}
