.video_container {
  margin: 0 auto;
  width: auto;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  position: relative;
}

.video_container iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
