.search_container {
  position: relative;
  width: var(--search-bar-width);
  height: calc(var(--search-bar-height) + 2em);
  left: var(--srch-margin-left);
  /* margin: 0 auto 0 0; */
  padding-top: var(--margin-bottom);
  margin-left: var(--srch-margin-left);
}

.searchBar_container {
  --side-anim-duration: 1.5s;
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: 3rem;
  background-color: var(--clr-surface1);
  padding: 0.1em 1em;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0.5em;
  box-shadow: var(--shadow-heavy);
  border: 1px solid transparent;
  transition: border 150ms ease-out;
}

.search_container > ul {
  position: absolute;
  width: 100%;
  top: calc(var(--search-bar-height) + 0.8em);
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 30;
  background-color: var(--clr-surface2);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  padding-right: 0.2em;
  box-shadow: var(--shadow-heavy);
}

.search__item * {
  cursor: default;
}

.search__item:first-child,
.search__item:last-child {
  padding-top: 1em;
}

.search__item {
  display: inline-grid;
  position: relative;
  grid-template-columns: var(--thumb-width) 0.6fr 0.4fr;
  gap: 0.5em;
  align-items: center;
  width: 100%;
  height: calc(var(--thumb-height) + 1em);
  transition: all 100ms linear;
  background-color: var(--clr-surface2);
}

.search__item > img {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: cover;
}

.search__item > span {
  max-height: var(--thumb-height);
  overflow: hidden;
}

.search__item:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0.2em;
  height: 100%;
  background-color: var(--clr-accent);
}

.searchBar_container form {
  width: 100%;
}

.searchBar_container > span {
  position: absolute;
  display: block;
}

/* Top */
.loading > span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, var(--clr-accent));
  animation: top-anim var(--side-anim-duration) linear infinite;
  animation-delay: calc((var(--side-anim-duration) / 4) * 1);
}

@keyframes top-anim {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

/* Right */
.loading > span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, var(--clr-accent));
  animation: right-anim var(--side-anim-duration) linear infinite;
  animation-delay: calc((var(--side-anim-duration) / 4) * 2);
}

@keyframes right-anim {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

/* Bottom */
.loading > span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, var(--clr-accent));
  animation: bottom-anim var(--side-anim-duration) linear infinite;
  animation-delay: calc((var(--side-anim-duration) / 4) * 3);
}

@keyframes bottom-anim {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

/* Left */
.loading > span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, var(--clr-accent));
  animation: left-anim var(--side-anim-duration) linear infinite;
}

@keyframes left-anim {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}

.search_input {
  width: 100%;
  position: relative;
  font-weight: bold;
  padding: 0.5rem;
  font-size: 1rem;
  color: var(--clr-title);
  border-top: 1px solid var(--clr-bg);
  border-bottom: 1px solid var(--clr-bg);
  outline: none;
  background: transparent;
  transition: all 100ms ease-in;
  border: none;
}

/* taken from https://css-tricks.com/almanac/selectors/p/placeholder/ */
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  padding-left: 0.2em;
}
::-moz-placeholder {
  /* Firefox 19+ */
  padding-left: 0.2em;
}
:-ms-input-placeholder {
  /* IE 10+ */
  padding-left: 0.2em;
}
:-moz-placeholder {
  /* Firefox 18- */
  padding-left: 0.2em;
}

.searchBar_container:focus-within {
  border: 1px solid var(--clr-btn);
}

.search__icon * {
  transform: scale(0.8);
  fill: var(--clr-btn);
  transition: all 150ms ease-out;
}

.search__icon:hover * {
  fill: var(--clr-accent);
  transform: scale(0.9);
}
