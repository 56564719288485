.queue_container {
  display: grid;
  width: 100%;
  height: 100%;
}

.queue_component {
  display: inline-grid;
  position: relative;
  width: 100%;
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: block;
  grid-template-rows: var(--header-height) repeat(auto-fill, 4em);
  gap: 0.5em;
  transition: all 100ms ease-out;
  font-size: 100%;
  margin: 0 auto;
  padding: 1em;
}

.queue__item {
  position: relative;
  display: inline-grid;
  height: 4em;
  grid-template-columns: 50px minmax(30%, 1fr) minmax(20%, 0.8fr) repeat(
      auto-fit,
      minmax(50px, 0.5fr)
    );
  gap: 0.2em;
  padding: 0 0.5em;
  margin: 0 0.1em;
  align-items: center;
  justify-items: center;
  transition: inherit;
  overflow: hidden;
  text-align: center;
  animation: push 500ms cubic-bezier(0.22, 1, 0.36, 1);
  z-index: 9;
  cursor: default;
}

.queue__item.current_item {
  background: var(--clr-surface3);
  box-shadow: var(--shadow-light);
  border-radius: var(--border-radius);
}

.queue__item > span {
  max-height: 90%;
  overflow-y: hidden;
  overflow-x: auto;
}

.queue__header {
  word-break: normal;
  color: var(--clr-btn);
  font-weight: 600;
  box-shadow: none;
  background: var(--clr-surface1);
  position: sticky;
  top: -1em;
  left: 0;
  width: 100%;
  height: var(--header-height);
  margin: 0;
  z-index: 10;
  user-select: none;
  transition: none;
}

/* TODO: find a better way to do this later */
.draggable {
  transform: scale(0.2) translateY(-85%);
  opacity: 50%;
}

.queue__item:hover {
  transform: scale(1.01);
  margin: 0;
}

.queue__header:hover {
  transform: none;
}

.queue__item.inactive {
  animation: pop 200ms ease-out;
  left: -100%;
}

@keyframes pop {
  0% {
    left: 0;
    opacity: 100;
  }
  99% {
    left: -99%;
    opacity: 100;
  }
  100% {
    left: -100%;
    opacity: 0;
    display: none;
  }
}

@keyframes push {
  0% {
    left: -100%;
  }
  100% {
    left: 0;
  }
}
