.slider_container {
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin: 0 1em;
}

.slider_container.disabled .track__left::before {
  opacity: 0;
}
.slider_container.disabled .slider__track,
.slider_container.disabled .track__left {
  background: var(--clr-btn-inactive);
}

.slider {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.slider__track {
  position: relative;
  width: 100%;
  height: var(--bar-height);
  border-radius: calc(var(--bar-height) / 2);
  background: var(--clr-surface3);
}

.track__left {
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: calc(var(--bar-height) / 2);
  background-color: var(--clr-accent);
  padding: 0 0.3em;
}

.track__left::before {
  content: '';
  position: absolute;
  top: calc(-1em / 8);
  right: calc(-1em / 8);
  width: calc(var(--bar-height) + calc(1em / 4));
  height: calc(var(--bar-height) + calc(1em / 4));
  border-radius: calc((var(--bar-height) + calc(1em / 4)) / 2);
  background-color: var(--clr-surface2);
  outline: 1px solid var(--clr-accent);
  box-shadow: var(--shadow-light);
}

.volume_counter {
  display: inline-block;
  width: 2em;
  font-size: 1.2em;
  font-weight: 800;
  padding: 0 1em;
  color: var(--clr-btn);
  text-align: left;
}
