.player_container {
  display: grid;
  height: auto;
  width: 100%;
  max-width: var(--player-width);
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  margin: 1em auto;
}

.player_container button {
  display: inline-flex;
  position: relative;
  border: none;
  background: transparent;
  cursor: pointer;
}

.player_container button svg {
  position: relative;
  fill: var(--clr-accent);
}

.player_container > * {
  margin: 0 auto;
  transform: scale(0.7);
}

/* .player_container button:focus {
  animation: click 100ms cubic-bezier(0.33, 1, 0.68, 1);
} */

@keyframes click {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(0.7);
  }
}

.sound_controller {
  grid-column: 1 / -1;
  width: 100%;
  display: flex;
  transform: scale(0.8);
  user-select: none;
}

.sound_controller svg {
  transform: scale(0.8);
}

.player_container .btn_inactive svg {
  fill: var(--clr-btn-inactive);
}

.btn_inactive {
  /* cursor: not-allowed; */
  cursor: default !important;
}

.player_container .btn_active svg {
  fill: var(--clr-btn-active);
}
