.navbar_container {
  width: var(--navbar-width);
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  padding: 1rem;
  align-content: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  text-align: center;
  gap: 1em;
  font-size: 1.5em;
  word-wrap: break-word;
  padding-bottom: var(--margin-bottom);
  background-color: var(--clr-surface1);
  box-shadow: var(--shadow-heavy);
}

.navbar_container > * {
  margin-left: 2em;
}

.navbar_container > div {
  display: inline-block;
  word-wrap: break-word;
  word-break: normal;
}

.logo_container {
  position: relative;
  margin: 0;
}

.logo_container svg {
  top: 0;
  left: 0;
  width: 100%;
  padding: 5px;
}

.navLink {
  cursor: pointer;
  color: var(--clr-btn);
  font-weight: 900;
}

.navLink:hover {
  cursor: default;
  color: var(--clr-accent);
}

.menu_btn {
  display: none;
}

@media screen and (max-width: 1150px) {
  .navbar_container {
    position: absolute;
    z-index: 99;
  }

  .navbar_container.active {
    left: 0;
    transition: left 500ms cubic-bezier(0.22, 1, 0.36, 1);
  }

  .navbar_container.hidden {
    left: -100%;
    transition: left 700ms linear;
  }

  .menu_btn {
    display: block;
    width: auto;
    position: fixed;
    top: calc(
      var(--dashboard-margin-top) + (var(--search-bar-height) / 2) - 1rem
    );
    left: 1rem;
    z-index: 97;
    padding: 1rem;
    background: var(--clr-bg);
    border-radius: var(--btn-border-radius);
  }

  .menu_btn svg {
    fill: var(--clr-btn-inactive);
    transition: fill 100ms ease-out;
  }
  .menu_btn:hover svg {
    fill: var(--clr-btn-active);
  }
}

.guilds_container {
  display: grid;
  width: 100%;
  max-height: 100%;
  overflow: auto;
  grid-template-rows: repeat(auto-fill, 1fr);
  gap: 0.5em;
  justify-content: flex-start;
  align-items: center;
  padding: 0.1em;
}

/* Hide Scrollbar */
.guilds_container::-webkit-scrollbar {
  display: none;
}
.guilds_container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.guilds_container::-webkit-scrollbar {
  background-color: #202324;
  color: #aba499;
  width: 3px;
}

.guilds_container::-webkit-scrollbar-corner {
  background-color: #181a1b;
}

.guilds_container::-webkit-scrollbar-thumb {
  background-color: #454a4d;
}

.guildIcon {
  position: relative;
  display: flex;
  background-color: var(--clr-surface2);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 0.1em;
  cursor: pointer;
}

.guildIcon:hover {
  transform: scale(1.1);
}

.guildIcon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.guildIcon.guildActive {
  grid-row: 1;
  transform: translateX(50px);
  border-radius: 15px;
  transition: transform 500ms cubic-bezier(0.22, 1, 0.36, 1),
    border-radius 500ms cubic-bezier(0.22, 1, 0.36, 1);
}

.guildIcon.guildActive img {
  border-radius: 0.5em;
  transform: scale(1.2);
}

.guildIcon.guildActive::before {
  content: '';
  background-color: var(--clr-title);
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: -60%;
  transform: translateY(-50%);
  animation: markerAnimation 500ms cubic-bezier(0.22, 1, 0.36, 1);
}

@keyframes markerAnimation {
  0% {
    left: 0;
  }
  100% {
    left: -60%;
  }
}

.navbar__footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
